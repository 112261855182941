import Layout from '../components/layout/LayoutComponent'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Caroucel from "../components/Caroucel"

const IndexPage = () => {

    const dataQuery = useStaticQuery(graphql`
      query {
        allFile(
          filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "servicios"}}
          sort: {fields: name}
        ) {
          nodes {
            childImageSharp {
                gatsbyImageData(width: 400)
            }
            name
          }
        }
      }
  
    `)
    

    const imagesData = dataQuery.allFile.nodes.map((node) => {
        return {
            image: getImage(node),
            title: node.name
        }
    });

  return (
    <Layout pageTitle="Inicio" content='' isHome={true}>
      <>
        <div className='sm:container px-5 md:p-0 w-full flex flex-col bg-primary/0 relative z-10 backdrop-blur-sm mx-auto'>
          <Caroucel title="Nuestros Servicios" dataQuery={imagesData}/>
        </div>
        
      </>
    </Layout>
  )
}

export default IndexPage

export const Head = () => (
    <>
      <title>Servicios | NecarMed</title>
    </>
  )